.App {
  padding: 20px;
}
.Chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90vw;
  height: 90vh;
}
